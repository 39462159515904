import { render, staticRenderFns } from "./Certificates.vue?vue&type=template&id=50835690&scoped=true"
import script from "./Certificates.vue?vue&type=script&lang=js"
export * from "./Certificates.vue?vue&type=script&lang=js"
import style0 from "./Certificates.vue?vue&type=style&index=0&id=50835690&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "50835690",
  null
  
)

export default component.exports